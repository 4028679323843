'use client';
import { createContext, PropsWithChildren, useState } from 'react';

export const HeaderStateContext = createContext<{
  stickyHeaderEnabled: boolean;
  setStickyHeaderEnabled: (nextState: boolean) => void;
}>({
  stickyHeaderEnabled: true,
  setStickyHeaderEnabled: () => {},
});

export const HeaderStateProvider = ({ children }: PropsWithChildren) => {
  const [stickyHeaderEnabled, setStickyHeaderEnabled] = useState(true);
  return (
    <HeaderStateContext.Provider
      value={{
        stickyHeaderEnabled,
        setStickyHeaderEnabled,
      }}
    >
      {children}
    </HeaderStateContext.Provider>
  );
};
